<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <el-form
            :inline="true"
            class="searchBoxForm"
            size="small"
            label-position="right"
          >
            <el-form-item label="阀门名称" class="searchItem">
              <el-input
                v-model="pageParams.valveName"
                placeholder="请输入"
                clearable
                class="search"
              ></el-input>
            </el-form-item>
            <el-form-item label="阀门种类" class="searchItem">
              <el-select
                v-model="pageParams.valveKind"
                placeholder="请选择"
                clearable
                class="search"
                @change="search"
              >
                <el-option
                  v-for="(item, index) in valueKindDict"
                  :key="index"
                  :label="item.name"
                  :value="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="阀门设施" class="searchItem">
              <el-select
                v-model="pageParams.valveFacilities"
                placeholder="请选择"
                clearable
                class="search"
                @change="search"
              >
                <el-option
                  v-for="(item, index) in valveFacilitiesDict"
                  :key="index"
                  :label="item.name"
                  :value="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属图层" class="searchItem">
              <el-cascader
                    v-model="pageParams.layerId"
                    :options="optionsList"
                    :props="props"
                    placeholder="请选择图层"
                    popper-class="gis-default"
                    style="width:100%"
                     @change="search"
                  >
                  </el-cascader>
           
          </el-form-item>
            <el-form-item label="" class="searchItem">
              <el-button type="primary" @click="search" size="mini"
                >查询</el-button
              >
              <el-button type="warning" @click="restSearch" size="mini"
                >重置</el-button
              >
              <el-button type="success" @click="exportFile" size="mini"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="contain">
        <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%; margin-top: 10px"
          border
          :header-cell-style="{
            color: '#333',
            fontFamily: 'MicrosoftYaHeiUI',
            fontSize: '14px',
            fontWeight: 900,
            textAlign: 'left',
            background: '#f8f8f9',
          }"
          height="calc(100vh - 340px)"
          :stripe="true"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="valveName"
            width="180"
            label="阀门名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="valveCode"
            label="设备编码"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="valveKindName"
            label="阀门种类"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="valveFacilitiesName"
            label="阀门设施"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="spec"
            label="规格"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="model"
            label="型号"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="installDate"
            label="安装日期"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="remark" label="备注" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="createBy"
            label="操作人"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="操作时间"
            width="140"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="layerName"
            label="所属图层"
            width="140"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="left" width="180">
            <template slot-scope="{ row }">
              <el-button type="text" @click="getDetail(row)" size="mini"
                >查看</el-button
              >
              <el-button type="text" style="color: #0A8E78" @click="setPosition(row)" size="mini"
                >定位</el-button
              >
              <el-button
                type="text" style="color: #FF8610"
                @click="deleteById(row.pointId, row.valveName)"
                size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="total">
          <el-pagination
            background
            layout="total, prev, pager, next,sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageParams.current"
            :page-sizes="[ 30, 50, 100]"
            :page-size="pageParams.size"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      title="阀门"
      :visible.sync="dialogVisible"
      :modal="true"
      :close-on-click-modal="false"
      width="700px"
      @close="close()"
      custom-class="dialog-class"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rule"
        label-width="110px"
        size="mini"
        :disabled="!isEdit"
      >
        <el-form-item label="所属图层:">
          <el-input
            v-model="form.layerName"
            placeholder="请输入所属图层"
            disabled
            maxlength="40"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备编号:" v-if="form.id">
          <el-input
            v-model="form.valveCode"
            placeholder="请输入设备编号"
            disabled
            maxlength="40"
          ></el-input>
        </el-form-item>
        <el-form-item label="经纬度:">
          <el-input
            v-model="form.lnglat"
            disabled
            placeholder="请输入经纬度"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备名称:" prop="valveName">
          <el-input
            v-model="form.valveName"
            placeholder="请输入设备名称"
          ></el-input>
        </el-form-item>

        <div class="flex-box">
          <el-form-item label="阀门种类:">
            <el-select
              v-model="form.valveKind"
              popper-class="gis-default"
              placeholder="请选择阀门种类"
            >
              <el-option
                v-for="item in valueKindDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="敷设方式:">
            <el-select
              v-model="form.layType"
              popper-class="gis-default"
              clearable
              placeholder="请选择敷设方式"
            >
              <el-option
                v-for="item in layTypeDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex-box">
          <el-form-item label="阀门设施:">
            <el-select
              v-model="form.valveFacilities"
              popper-class="gis-default"
              clearable
              placeholder="请选择阀门设施"
            >
              <el-option
                v-for="item in valveFacilitiesDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="埋深:" prop="depth">
            <el-input v-model="form.depth" placeholder="请输入埋深"></el-input>
          </el-form-item>
        </div>
        <div class="flex-box">
          <el-form-item label="阀井深度:" prop="valveWellDepth"  v-if="form.valveFacilities =='2'">
            <el-input
              v-model="form.valveWellDepth"
              placeholder="请输入阀井深度"
            ></el-input>
          </el-form-item>
          <el-form-item label="阀井类型:"  v-if="form.valveFacilities =='2'">
            <el-select
              v-model="form.valveType"
              popper-class="gis-default"
              clearable
              placeholder="请选择阀井类型"
            >
              <el-option
                v-for="item in valveTypeDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
       
        </div>
        <div class="flex-box">
          <el-form-item label="规格:">
            <el-input
              v-model="form.spec"
              maxlength="40"
              placeholder="请输入规格"
            ></el-input>
          </el-form-item>
          <el-form-item label="型号:">
            <el-input
              v-model="form.model"
              maxlength="40"
              placeholder="请输入型号"
            ></el-input>
          </el-form-item>
        </div>
        <div class="flex-box">
          <el-form-item label="设备状态:" prop="generalStatus">
            <el-select
              v-model="form.generalStatus"
              popper-class="gis-default"
              placeholder="请选择设备状态"
            >
              <el-option
                v-for="item in optionsStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="连接方式:">
            <el-select
              v-model="form.connectType"
              popper-class="gis-default"
              clearable
              placeholder="请选择连接方式"
            >
              <el-option
                v-for="item in connectTypeDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex-box">
          <el-form-item label="安装时间:" prop="installDate">
            <el-date-picker
              v-model="form.installDate"
              type="date"
              :clearable="false"
              :editable="false"
              popper-class="datePickerClass"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="附着压力级别:" prop="pressureLevel">
            <el-select
              v-model="form.pressureLevel"
              popper-class="gis-default"
              placeholder="请选择压力级别"
            >
              <el-option
                v-for="item in pressureLevelDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="所在街道:">
            <el-input v-model="form.road" placeholder="请输入备注"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="生产厂家:">
            <el-input
              v-model="form.factory"
              maxlength="40"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="备注:">
            <el-input
              v-model="form.remark"
              maxlength="60"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          
        </div>
        <div>
          <el-form-item label="所在地址:" prop="">
            <el-input v-model="form.address" :disabled="isEdit? false: true" maxlength="60"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="btn">
        <el-button size="mini" @click="close(1)">取消</el-button>
        <el-button v-if="isEdit" type="primary" size="mini" @click="submit"
          >保存</el-button
        >
        <el-button v-else type="primary" size="mini" @click="update"
          >编辑</el-button
        >
      </div>
    </el-dialog>
    <station-location ref="stationLocation"></station-location>
  </div>
</template>

<script>
import '../Gis/style/skin.scss'
import pageMixins from "@/utils/pageMixins";
import { exportMethod } from "../../common/js/exportExcel";
import {
  valveExportStandingBook,
  valvePageStandingBook,
} from "../../RequestPort/gisbook";
import { commonDict, serverMap } from "../Gis/utils/dict";
import { findDictTree } from "../Gis/apis/commonType";
import { valueUpdate, valveDelete, valveFindById } from "../Gis/apis/tool";
import StationLocation from './stationLocation.vue';
import coverlayer from '../Gis/apis/coverlayer';
export default {
  name: "PipeLineList",
  components: {StationLocation},
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        valveKind: "",
        valveFacilities: "",
        current: 1,
        size: 100,
      }, //  分页查询参数
      total: null,
      tableData: [], //  表格数据
      isEdit: false,
      dialogVisible: false,
      form: {},
      rule: {
        valveName: [
          { required: true, message: "请输入设备名称", trigger: "change" },
          { max: 100, message: "超出最大限制", trigger: ["change", "blur"] },
        ],
        useAccord: [
          { max: 100, message: "超出最大限制", trigger: ["change", "blur"] },
        ],
        pressureLevel: [
          { required: true, message: "请选择压力级别", trigger: "change" },
        ],
        generalStatus: [
          { required: true, message: "请选择设备状态", trigger: "change" },
        ],

        controlType: [
          { required: true, message: "请选择控制器类型", trigger: "change" },
        ],
        transmit: [
          { required: true, message: "请选择是否远传", trigger: "change" },
        ],
        online: [
          { required: true, message: "请选择是否在线", trigger: "change" },
        ],
        valveWellDepth: [
          {
            pattern:
              /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确格式,可保留2位小数",
          },
        ],
        depth: [
          {
            pattern:
              /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确格式,可保留2位小数",
          },
        ],
      },
      pressureLevelDict: [],
      connectTypeDict: [],
      optionsStatus: [],
      valveTypeDict: [],
      valveFacilitiesDict: serverMap[1014],
      layTypeDict: [],
      valueKindDict: [],
      formOld:{},
       optionsAideo: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      optionsStatus: [
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '停用'
        }
      ],
      optionsList:[],
      props: {
        emitPath: false,
        label: 'name',
        value: 'id'
      },
    };
  },
  mounted() {
    this.loadList(this.pageParams);
    this.getCodeName();
  },
  methods: {
    async getCodeName(type) {
      let p = { sysType: 1}
        let res = await coverlayer.getFindTree(p)
        this.optionsList = res.data
      let code =
        commonDict[100003].code +
        ',' +
        commonDict[100004].code +
        ',' +
        commonDict[100005].code +
        ',' +
        commonDict[100006].code +
        ',' +
        commonDict[100007].code+
        ',' +
        commonDict[300001].code+
        ',' +
        commonDict[300002].code
        
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.valueKindDict = res.data[commonDict[100003].code]
          this.layTypeDict = res.data[commonDict[100004].code]
          this.controlTypeDict = res.data[commonDict[100005].code]
          this.connectTypeDict = res.data[commonDict[100006].code]
          this.switchTypeDict = res.data[commonDict[100007].code]
         // this.valveFacilitiesDict = res.data[commonDict[300001].code]
          this.valveTypeDict = res.data[commonDict[300002].code]
        }
        this.pressureLevelDict = serverMap['1006']
        
      })
      // findManufacturerOptions({ eqType: this.eqType, companyId: getCompanyId() }).then(res => {
      //   if (res.data) {
      //     this.factoryDict = res.data
      //   }
      // })
    },
    submit() {
      this.$refs.form.validate(valid => {
        if(valid){
          delete this.form.point
          valueUpdate(this.form).then(res=>{
            if(res.code==200){
              this.$message.success(res.msg)
              this.loadList(this.pageParams);
              this.isEdit = false
            }else{
              this.$message.error(res.msg)
            }
          })
        }
        
      })
      
    },
    update() {
      this.isEdit = true
      this.formOld = this.form
    },
    close(flag) {
      if(this.isEdit&flag==1&&this.form.id){
        this.isEdit = false
        this.form = this.formOld
      }else{
        this.dialogVisible = false
        this.form = {}
      }
    },
    exportFile() {
      valveExportStandingBook(this.pageParams).then((res) => {
        exportMethod(res, "阀门台账报表");
      });
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        valveKind: "",
        valveFacilities: "",
        current: 1,
        size: 100,
      };
      this.loadList(this.pageParams);
    },
    loadList(obj) {
      valvePageStandingBook(obj).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
  
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    getDetail(row) {
      valveFindById({ id: row.id }).then(res => {
        this.form = res.data
         this.form.lnglat = row.point.replace('POINT(', '').replace(')', '')
        this.isEdit = false
        this.dialogVisible = true;
      })
    },
    setPosition(row) {
      let data = {}
      valveFindById({ id: row.id }).then(res => {
        data = res.data;
        data.showType = 'valve'
        data.title="阀门信息"
         this.$refs.stationLocation.openDialog(data,row.point)
      })
    },
    deleteById(id, name) {
      this.$confirm(`是否确认删除【${name}】阀门信息？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
        valveDelete({ pointId: id })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
            this.loadList(this.pageParams);
          })
          // .catch((e) => this.$message.error(e.message));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool {
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.all {
  background-color: #f3f4f8;
  height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow: auto;
  height: calc(100vh - 120px);
}
.search {
  width: 215px;
}
.contain {
  height: 690px;
}
.total {
  margin-left: 15px;
  margin-top: 10px;
}
.table {
  overflow: auto;
  height: 520px;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  .el-input {
    width: 190px;
  }
  .el-select {
    width: 190px;
  }
}
.btn {
  text-align: right;
}
</style>